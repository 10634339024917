import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import wood from '../assets/wood.png';
import fire from '../assets/fire.png';
import earth from '../assets/earth.png';
import metal from '../assets/metal.png';
import water from '../assets/water.png';

const Stry = () => {
  useEffect(() => {
    Aos.init({ duration: 4000 });
  }, [])
}
class NftTypes extends Component {

  state = {
    _imageOpen: 0
  }

  imageOpen = async event => {
    event.preventDefault();

    this.setState({ _imageOpen: 1 });
  }

  render() {
    return (

      <div class="boxWrapNFT">

        <div class="about2">
          <div class="storyCon2">
            <div class="conT2">TYPES OF NFT</div>
            <p>- There is a max supply of 12’000 NFTs. </p>
            <p>- There are 200 rare NFTs +800 normal NFTs of each zodiac.</p>

            <div class="type">
              <div>
                <div class="typeH">NORMAL NFT</div>
                <div class="typeCon">There Are 5 Colors Of Each Zodiac Reflecting The Element of this zodiac.</div>
                <div class="tableMain">
                  <table>
                    <tr>
                      <th>WOOD</th>
                      <th>FIRE</th>
                      <th>EARTH</th>
                      <th>METAL</th>
                      <th>WATER</th>
                    </tr>
                    <tr>
                      <td><img src={wood} /></td>
                      <td><img src={fire} /></td>
                      <td><img src={earth} /></td>
                      <td><img src={metal} /></td>
                      <td><img src={water} /></td>
                    </tr>

                  </table>
                </div>
                <div class="typeCon">The years of each zodiac are flashing around it and the sign is flashing on the chest of each Zodiac Hero.</div>
              </div>
              <p></p>

              <div>
                <div class="typeH">RARE NFT</div>
                <div class="typeCon">Additional to the regular NFT Rare ones show the lucky number colored by the lucky color of the Zodiac.</div>
                <div class="typeCon">In addition to a luxurious traits that do not exist in the normal NFT.</div>
              </div>
            </div>
          </div>
          <div class="tableMain">
            <table>
              <tr>
                <th>Zodiac</th>
                <th>Lucky Numbers</th>
                <th>Lucky Colors</th>
              </tr>
              <tr>
                <td>Dog</td>
                <td>3, 4, 9</td>
                <td>green, pink, red</td>
              </tr>
              <tr>
                <td>Dragon</td>
                <td>1, 6, 7</td>
                <td>gold, gray, silver</td>
              </tr>
              <tr>
                <td>Goat</td>
                <td>3, 4, 9</td>
                <td>green, purple, red</td>
              </tr>
              <tr>
                <td>Horse</td>
                <td>2, 3, 7</td>
                <td>brown, purple, yellow</td>
              </tr>
              <tr>
                <td>Monkey</td>
                <td>1, 7, 8</td>
                <td>blue, gold, white</td>
              </tr>
              <tr>
                <td>Ox</td>
                <td>1, 4, 9</td>
                <td>blue, purple, red</td>
              </tr>
              <tr>
                <td>Pig</td>
                <td>2, 5, 8</td>
                <td>brown, gray, yellow</td>
              </tr>
              <tr>
                <td>Rabbit</td>
                <td>3, 4, 9</td>
                <td>blue, pink, purple</td>
              </tr>
              <tr>
                <td>Rat</td>
                <td>2, 3, 5</td>
                <td>blue, gold, green</td>
              </tr>
              <tr>
                <td>Rooster</td>
                <td>5, 7, 8</td>
                <td>brown, gold, yellow</td>
              </tr>
              <tr>
                <td>Snake</td>
                <td>2, 8, 9</td>
                <td>brown, red, yellow</td>
              </tr>
              <tr>
                <td>Tiger</td>
                <td>1, 3, 4</td>
                <td>blue, orange, white</td>
              </tr>
            </table>

          </div>
        </div>
      </div>
    )
  }
}

export default NftTypes;

