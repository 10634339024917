import './App.css';
import React, { Component } from 'react';
import Story from "./components/story";
import Ticker from './components/ticker';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import logo from './assets/logo.gif';
import logo2 from './assets/LOGO.jpeg';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import mintPic from './assets/14.gif';
import Carousel from './components/carousel';
import NftTypes from './components/nftTypes';
import Footer from './components/footer';
import Team from './components/team';
import download from './assets/download.png';
import $ from "jquery";
import img1 from './assets/1.gif';
import img2 from './assets/2.gif';
import img3 from './assets/3.gif';
import img4 from './assets/4.gif';
import img5 from './assets/5.gif';
import img6 from './assets/6.gif';
import img7 from './assets/7.gif';
import img8 from './assets/8.gif';
import img9 from './assets/9.gif';
import img10 from './assets/10.gif';
import img11 from './assets/11.gif';
import img12 from './assets/12.gif';
import img13 from './assets/13.gif';
import img14 from './assets/14.gif';
import img15 from './assets/15.gif';
import img16 from './assets/16.gif';
import img17 from './assets/17.gif';
import img18 from './assets/18.gif';
import img19 from './assets/19.gif';
import img20 from './assets/20.gif';
import img21 from './assets/21.gif';
import img22 from './assets/22.gif';
import img23 from './assets/23.gif';
import img24 from './assets/24.gif';
import down from './assets/down-arrow.png';
import poly from './assets/poly.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let usdtAmount = 10000000;
let selectedNFTIMG = './assets/1.gif';
let sectionCount = '';

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

const osL = () => {
	window.open("https://opensea.io/collection/zodiac-hero");
}

const linkCon = () => {
	window.open("https://polygonscan.com/address/0x68ddA8B20bc2ec86e88d055d39bC345209C455AA#code");
}

const cs = () => {
    window.open("https://www.coinscope.co/coin/zh");
}

const WP = () => {
	window.open("https://zodiachero.com/zodiacherowhitepaper.pdf");
}

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_symbol",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mintAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_priceSection",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "startingID",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "endingID",
				"type": "uint256"
			}
		],
		"name": "preMintNFTs",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseExtension",
				"type": "string"
			}
		],
		"name": "setBaseExtension",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256[]",
				"name": "_price",
				"type": "uint256[]"
			}
		],
		"name": "setBulkSetPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimals",
				"type": "uint256"
			}
		],
		"name": "setDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_txn",
				"type": "uint256"
			}
		],
		"name": "setMax_per_txn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_premintwallet",
				"type": "address"
			}
		],
		"name": "setPremintwallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bool",
				"name": "_public_mint_status",
				"type": "bool"
			}
		],
		"name": "setPublic_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_royaltyAddress",
				"type": "address"
			}
		],
		"name": "setRoyaltyAddress",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "sectionNumber",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_price",
				"type": "uint256"
			}
		],
		"name": "setSectionPrice",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseExtension",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "calculateRoyalty",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_txn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "premintwallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "sectionCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "sectionPrice",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x68ddA8B20bc2ec86e88d055d39bC345209C455AA";
//const address = "0x07FbE69D632dECA2BF9810D912d690e23AD6c0d4";
//const address = "0x908d90F626E8C288dEfAeA2df57CED478053EdBd";

/*const token_ABI = [
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "authorizer",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		}
	  ],
	  "name": "AuthorizationCanceled",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "authorizer",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		}
	  ],
	  "name": "AuthorizationUsed",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "Blacklisted",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": false,
		  "internalType": "address",
		  "name": "userAddress",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "address payable",
		  "name": "relayerAddress",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bytes",
		  "name": "functionSignature",
		  "type": "bytes"
		}
	  ],
	  "name": "MetaTransactionExecuted",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [],
	  "name": "Pause",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newRescuer",
		  "type": "address"
		}
	  ],
	  "name": "RescuerChanged",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "previousAdminRole",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "newAdminRole",
		  "type": "bytes32"
		}
	  ],
	  "name": "RoleAdminChanged",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "sender",
		  "type": "address"
		}
	  ],
	  "name": "RoleGranted",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "sender",
		  "type": "address"
		}
	  ],
	  "name": "RoleRevoked",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "UnBlacklisted",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [],
	  "name": "Unpause",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "APPROVE_WITH_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "BLACKLISTER_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "CANCEL_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "DECREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "DEFAULT_ADMIN_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "DEPOSITOR_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "DOMAIN_SEPARATOR",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "EIP712_VERSION",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "INCREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "META_TRANSACTION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "PAUSER_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "PERMIT_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "RESCUER_ROLE",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "WITHDRAW_WITH_AUTHORIZATION_TYPEHASH",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		}
	  ],
	  "name": "allowance",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validAfter",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validBefore",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "approveWithAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "authorizer",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		}
	  ],
	  "name": "authorizationState",
	  "outputs": [
		{
		  "internalType": "enum GasAbstraction.AuthorizationState",
		  "name": "",
		  "type": "uint8"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "blacklist",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "blacklisters",
	  "outputs": [
		{
		  "internalType": "address[]",
		  "name": "",
		  "type": "address[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "authorizer",
		  "type": "address"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "cancelAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "decimals",
	  "outputs": [
		{
		  "internalType": "uint8",
		  "name": "",
		  "type": "uint8"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "subtractedValue",
		  "type": "uint256"
		}
	  ],
	  "name": "decreaseAllowance",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "decrement",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validAfter",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validBefore",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "decreaseAllowanceWithAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "user",
		  "type": "address"
		},
		{
		  "internalType": "bytes",
		  "name": "depositData",
		  "type": "bytes"
		}
	  ],
	  "name": "deposit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "userAddress",
		  "type": "address"
		},
		{
		  "internalType": "bytes",
		  "name": "functionSignature",
		  "type": "bytes"
		},
		{
		  "internalType": "bytes32",
		  "name": "sigR",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "sigS",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "sigV",
		  "type": "uint8"
		}
	  ],
	  "name": "executeMetaTransaction",
	  "outputs": [
		{
		  "internalType": "bytes",
		  "name": "",
		  "type": "bytes"
		}
	  ],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		}
	  ],
	  "name": "getRoleAdmin",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "getRoleMember",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		}
	  ],
	  "name": "getRoleMemberCount",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "grantRole",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "hasRole",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "addedValue",
		  "type": "uint256"
		}
	  ],
	  "name": "increaseAllowance",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "increment",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validAfter",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validBefore",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "increaseAllowanceWithAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "newName",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "newSymbol",
		  "type": "string"
		},
		{
		  "internalType": "uint8",
		  "name": "newDecimals",
		  "type": "uint8"
		},
		{
		  "internalType": "address",
		  "name": "childChainManager",
		  "type": "address"
		}
	  ],
	  "name": "initialize",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "initialized",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "isBlacklisted",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "nonces",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "pause",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "paused",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "pausers",
	  "outputs": [
		{
		  "internalType": "address[]",
		  "name": "",
		  "type": "address[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "spender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "deadline",
		  "type": "uint256"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "permit",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "renounceRole",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "contract IERC20",
		  "name": "tokenContract",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "rescueERC20",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "rescuers",
	  "outputs": [
		{
		  "internalType": "address[]",
		  "name": "",
		  "type": "address[]"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "role",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "revokeRole",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "recipient",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "transfer",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "sender",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "recipient",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validAfter",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validBefore",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "transferWithAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "account",
		  "type": "address"
		}
	  ],
	  "name": "unBlacklist",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "unpause",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "newName",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "newSymbol",
		  "type": "string"
		}
	  ],
	  "name": "updateMetadata",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "amount",
		  "type": "uint256"
		}
	  ],
	  "name": "withdraw",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "value",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validAfter",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "validBefore",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32",
		  "name": "nonce",
		  "type": "bytes32"
		},
		{
		  "internalType": "uint8",
		  "name": "v",
		  "type": "uint8"
		},
		{
		  "internalType": "bytes32",
		  "name": "r",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32",
		  "name": "s",
		  "type": "bytes32"
		}
	  ],
	  "name": "withdrawWithAuthorization",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	}
];*/

const token_ABI = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "authorizer",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			}
		],
		"name": "AuthorizationCanceled",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "authorizer",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			}
		],
		"name": "AuthorizationUsed",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Blacklisted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "userAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address payable",
				"name": "relayerAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bytes",
				"name": "functionSignature",
				"type": "bytes"
			}
		],
		"name": "MetaTransactionExecuted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Pause",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "newRescuer",
				"type": "address"
			}
		],
		"name": "RescuerChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "previousAdminRole",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "newAdminRole",
				"type": "bytes32"
			}
		],
		"name": "RoleAdminChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleGranted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleRevoked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "UnBlacklisted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [],
		"name": "Unpause",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "APPROVE_WITH_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BLACKLISTER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "CANCEL_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DECREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DEFAULT_ADMIN_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DEPOSITOR_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DOMAIN_SEPARATOR",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "EIP712_VERSION",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "INCREASE_ALLOWANCE_WITH_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "META_TRANSACTION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "PAUSER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "PERMIT_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "RESCUER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WITHDRAW_WITH_AUTHORIZATION_TYPEHASH",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validAfter",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validBefore",
				"type": "uint256"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "approveWithAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "authorizer",
				"type": "address"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			}
		],
		"name": "authorizationState",
		"outputs": [
			{
				"internalType": "enum GasAbstraction.AuthorizationState",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "blacklist",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "blacklisters",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "authorizer",
				"type": "address"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "cancelAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "decrement",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validAfter",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validBefore",
				"type": "uint256"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "decreaseAllowanceWithAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "depositData",
				"type": "bytes"
			}
		],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "userAddress",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "functionSignature",
				"type": "bytes"
			},
			{
				"internalType": "bytes32",
				"name": "sigR",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "sigS",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "sigV",
				"type": "uint8"
			}
		],
		"name": "executeMetaTransaction",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			}
		],
		"name": "getRoleAdmin",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "getRoleMember",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			}
		],
		"name": "getRoleMemberCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "hasRole",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "increment",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validAfter",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validBefore",
				"type": "uint256"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "increaseAllowanceWithAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "newName",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "newSymbol",
				"type": "string"
			},
			{
				"internalType": "uint8",
				"name": "newDecimals",
				"type": "uint8"
			},
			{
				"internalType": "address",
				"name": "childChainManager",
				"type": "address"
			}
		],
		"name": "initialize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initialized",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "isBlacklisted",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "nonces",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pausers",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "deadline",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "permit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "renounceRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IERC20",
				"name": "tokenContract",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "rescueERC20",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rescuers",
		"outputs": [
			{
				"internalType": "address[]",
				"name": "",
				"type": "address[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "revokeRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "recipient",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validAfter",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validBefore",
				"type": "uint256"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "transferWithAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "unBlacklist",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "newName",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "newSymbol",
				"type": "string"
			}
		],
		"name": "updateMetadata",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validAfter",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "validBefore",
				"type": "uint256"
			},
			{
				"internalType": "bytes32",
				"name": "nonce",
				"type": "bytes32"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "withdrawWithAuthorization",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

//const token_address = "0xDD9185DB084f5C4fFf3b4f70E7bA62123b812226";
const token_address = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";

let contract;
let token_contract;
var imageSrc = mintPic;
let _selectedNFT;

const imgAppear1 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/1.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear2 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/2.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear3 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/3.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear4 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/4.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear5 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/5.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear6 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/6.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear7 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/7.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear8 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/8.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear9 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/9.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear10 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/10.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear11 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/11.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear12 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/12.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear13 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/13.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear14 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/14.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear15 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/15.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear16 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/16.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear17 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/17.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear18 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/18.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear19 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/19.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear20 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/20.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear21 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/21.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear22 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/22.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear23 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/23.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

const imgAppear24 = () => {

	$(function () {
		var imgUrl = 'https://zh-assets.pages.dev/images/24.gif';
		$('.selectedImg2').css('background-image', 'url(' + imgUrl + ')');
		console.log("selected");
		console.log(imgUrl);
	});

	setTimeout(function () {
		$('#content').show(); // Show the content after the delay
	}, 4000);

}

class App extends Component {

	state = {
		_imageSrc: "",
		walletAddress: "",
		totalSupply: "",
		totalSupplyChanging: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_select: 0,
		pressBtnName: "Select Zodiac Hero",
		_approved: '',
		_selectedNFT: '',
		_selectedNFTID: '',
		_selectedNFTURL: '',
		_totalSupplyVar: '12000',
		_sectionCount: ''
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {

		try {

			if (localStorage?.getItem('src') == "1") {
				localStorage.setItem('src', "2");

				this.setState({ gallery: "1" });

			} else

				if (localStorage?.getItem('src') == "2") {
					localStorage.setItem('src', "3");

					this.setState({ gallery: "2" });

				} else

					if (localStorage?.getItem('src') == "3") {
						localStorage.setItem('src', "4");

						this.setState({ gallery: "3" });

					} else

						if (localStorage?.getItem('src') == "4") {
							localStorage.setItem('src', "1");

							this.setState({ gallery: "4" });

						} else {

							localStorage.setItem('src', "1");
							this.setState({ gallery: "1" });

						}


		} catch (err) {
			console.log(err);
		}

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);
					token_contract = new web3.eth.Contract(token_ABI, token_address);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 137;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/']*/

													chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}



								let allowance = await token_contract.methods.allowance(account, address).call();

								if (allowance >= usdtAmount) {
									this.setState({ _approved: true });
									console.log("true Allowance:" + allowance);


								} else {
									this.setState({ _approved: false });
									console.log("false Allowance:" + allowance);

								}



								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);
								this.setState({ totalSupplyChanging: totalSupplyNFT });



								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });



								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });



								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);



								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);



								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);

								//.........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 12000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 12000) {

										onlyLeft = 12000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///...............................///



								//.........................................................................//

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 137;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													/*chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],*/

													chainName: 'Polygon Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://polygon-rpc.com/'],

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}



								let allowance = await token_contract.methods.allowance(account, address).call();

								if (allowance >= usdtAmount) {
									this.setState({ _approved: true });
									console.log("true Allowance:" + allowance);


								} else {
									this.setState({ _approved: false });
									console.log("false Allowance:" + allowance);

								}



								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);
								this.setState({ totalSupplyChanging: totalSupplyNFT });



								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });



								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });



								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);



								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);



								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);

								//.........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 12000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 12000) {

										onlyLeft = 12000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///...............................///



								//.........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 12000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 12000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 12000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 12000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1, this.state._selectedNFTID).send({ /*gasLimit: 385000, */gasPrice: 250000000000, gasLimit: 685000, from: account });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1, this.state._selectedNFTID).send({ /*gasLimit: 385000,*/gasPrice: 250000000000, gasLimit: 685000, from: account });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();
		await new Promise(resolve => setTimeout(resolve, 2000));
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					rpc: {
						137: "https://quick-patient-wind.matic.discover.quiknode.pro/a464dfe78a64cdf23967e90131e1f459d7c2ab38/" // replace this with QuickNode URL
					}
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);
			token_contract = new web3.eth.Contract(token_ABI, token_address);

			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 137;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
								window.location.reload(true);

							} catch (ex) {
								console.log(ex)
							}



							let allowance = await token_contract.methods.allowance(account, address).call();

							if (allowance >= usdtAmount) {
								this.setState({ _approved: true });
								console.log("true Allowance:" + allowance);


							} else {
								this.setState({ _approved: false });
								console.log("false Allowance:" + allowance);

							}



							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);
							this.setState({ totalSupplyChanging: totalSupplyNFT });



							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });



							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });



							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);



							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							//.........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 12000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 12000) {

									onlyLeft = 12000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///...............................///



							//.........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 137;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
								window.location.reload(true);
							} catch (ex) {
								console.log(ex)
							}



							let allowance = await token_contract.methods.allowance(account, address).call();

							if (allowance >= usdtAmount) {
								this.setState({ _approved: true });
								console.log("true Allowance:" + allowance);


							} else {
								this.setState({ _approved: false });
								console.log("false Allowance:" + allowance);

							}



							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);
							this.setState({ totalSupplyChanging: totalSupplyNFT });



							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });



							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });



							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);



							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 12000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 12000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///...............................///



							//.........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	selected = async event => {
		event.preventDefault();

		this.setState({ _select: 1 });
	}

	selectedClose = async event => {
		event.preventDefault();

		this.setState({ _select: 0 });
	}

	approve = async event => {
		event.preventDefault();

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					console.log("usdtAmount:" + usdtAmount);

					let allowance = await token_contract.methods.allowance(account, address).call();

					if (allowance > 0) {

						await token_contract.methods.increaseAllowance(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ gasPrice: 250000000000, gasLimit: 685000, from: account });
						this.setState({ _approved: true });
						window.location.reload(true);
					} else {

						await token_contract.methods.approve(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ gasPrice: 250000000000, gasLimit: 685000, from: account });
						this.setState({ _approved: true });
						window.location.reload(true);
					}



				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + usdtAmount);

					let allowance = await token_contract.methods.allowance(account, address).call();

					if (allowance > 0) {

						await token_contract.methods.increaseAllowance(address, usdtAmount).send({ maxPriorityFeePerGas: 400000000000, gasLimit: 685000, from: account });
						this.setState({ _approved: true });
						window.location.reload(true);
					} else {

						await token_contract.methods.approve(address, usdtAmount).send({ maxPriorityFeePerGas: 400000000000, gasLimit: 685000, from: account });
						this.setState({ _approved: true });
						window.location.reload(true);
					}



				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}


	}

	img1F = async event => {
		event.preventDefault();

		sectionCount = await contract.methods.sectionCount(1).call();

		this.setState({
			_selectedNFT: "Rare Dog",
			_selectedNFTID: 1,
			_selectedNFTURL: selectedNFTIMG // Set the URL for this option
		});

		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName: " + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear1();
		this.setState({ _totalSupplyVar: 125 });
		this.setState({ totalSupplyChanging: sectionCount });

	}

	img2F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(2).call();

		this.setState({ _selectedNFT: "Normal Dog", _selectedNFTID: 2 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear2();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
		this.setState({ totalSupplyChanging: sectionCount });
	}

	img3F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(3).call();

		this.setState({ _selectedNFT: "Rare Dragon", _selectedNFTID: 3 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear3();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
		this.setState({ totalSupplyChanging: sectionCount });

	}

	img4F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(4).call();

		this.setState({ _selectedNFT: "Normal Dragon", _selectedNFTID: 4 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear4();
		this.setState({ totalSupplyChanging: sectionCount });
	}

	img5F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(5).call();

		this.setState({ _selectedNFT: "Rare Goat", _selectedNFTID: 5 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear5();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img6F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(6).call();

		this.setState({ _selectedNFT: "Normal Goat", _selectedNFTID: 6 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear6();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img7F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(7).call();

		this.setState({ _selectedNFT: "Rare Horse", _selectedNFTID: 7 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear7();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img8F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(8).call();

		this.setState({ _selectedNFT: "Normal Horse", _selectedNFTID: 8 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear8();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img9F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(9).call();

		this.setState({ _selectedNFT: "Rare Monkey", _selectedNFTID: 9 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear9();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img10F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(10).call();

		this.setState({ _selectedNFT: "Normal Monkey", _selectedNFTID: 10 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear10();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img11F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(11).call();

		this.setState({ _selectedNFT: "Rare Ox", _selectedNFTID: 11 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear11();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img12F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(12).call();

		this.setState({ _selectedNFT: "Normal Ox", _selectedNFTID: 12 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear12();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img13F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(13).call();

		this.setState({ _selectedNFT: "Rare Pig", _selectedNFTID: 13 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear13();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img14F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(14).call();

		this.setState({ _selectedNFT: "Normal Pig", _selectedNFTID: 14 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear14();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img15F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(15).call();

		this.setState({ _selectedNFT: "Rare Rabbit", _selectedNFTID: 15 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear15();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img16F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(16).call();

		this.setState({ _selectedNFT: "Normal Rabbit", _selectedNFTID: 16 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear16();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img17F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(17).call();

		this.setState({ _selectedNFT: "Rare Rat", _selectedNFTID: 17 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear17();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img18F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(18).call();

		this.setState({ _selectedNFT: "Normal Rat", _selectedNFTID: 18 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear18();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img19F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(19).call();

		this.setState({ _selectedNFT: "Rare Rooster", _selectedNFTID: 19 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear19();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img20F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(20).call();

		this.setState({ _selectedNFT: "Normal Rooster", _selectedNFTID: 20 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear20();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img21F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(21).call();

		this.setState({ _selectedNFT: "Rare Snake", _selectedNFTID: 21 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear21();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img22F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(22).call();

		this.setState({ _selectedNFT: "Normal Snake", _selectedNFTID: 22 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear22();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img23F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(23).call();

		this.setState({ _selectedNFT: "Rare Tiger", _selectedNFTID: 23 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		console.log("pressBtnName :" + this.state._selectedNFT);
		this.setState({ _select: 0 });
		imgAppear23();
		this.setState({ _totalSupplyVar: 124 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	img24F = async event => {
		event.preventDefault();
		sectionCount = await contract.methods.sectionCount(24).call();

		this.setState({ _selectedNFT: "Normal Tiger", _selectedNFTID: 24 });
		await new Promise(resolve => setTimeout(resolve, 2000));

		this.setState({ pressBtnName: this.state._selectedNFT });
		console.log("clicked");
		this.setState({ _select: 0 });
		imgAppear24();
		this.setState({ _totalSupplyVar: 499 }); this.setState({ totalSupplyChanging: sectionCount });
	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<div class="spinner"></div>
						<div class="txt">ZODIAC HERO</div>
					</div>
					<div class="cont">

						{this.state._navbarOpen < 1 ?
							(<div class="miniBarMain">
								<div ><img class="logo" src={logo2} /></div>

								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">
								<div ><img class="logo" src={logo} /></div>

								<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}

						<div class="headers">

							<div class="h1">

								<div class="logoDiv"><img class="logo" src={logo2} /></div>

								<div class="connect">
									<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="types" spy={true} smooth={true} duration={550}>TYPES OF NFT</Link></div>
									<div ><Link activeClass="" id="fontSize" to="gallery" spy={true} smooth={true} duration={550}>GALLERY</Link></div>
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINTING</Link></div>
									<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
									<div id="fontSize" onClick={WP}>WHITEPAPER</div>
									<div id="fontSize" onClick={cs}>AUDIT</div>

								</div>

								<div class="right">

									{this.state.walletAddress === '' ?
										(<form class="connect2">
											<Link to="mint" spy={true} smooth={true} duration={550}><button class="connectBtn" >CONNECT</button></Link>
										</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>

							</div>

						</div>

						{this.state._navbarOpen > 0 ?
							(<div class="littleNav">
								<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="types" spy={true} smooth={true} duration={550}>TYPES OF NFT</Link></div>
								<div ><Link activeClass="" id="fontSize" to="gallery" spy={true} smooth={true} duration={550}>GALLERY</Link></div>
								<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINTING</Link></div>
								<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>TEAM</Link></div>
								<div id="fontSize" onClick={WP}>WHITEPAPER</div>
								<div id="fontSize" onClick={cs}>AUDIT</div>

							</div>) : null}

						<div class="introduction">
							<div class="in1">ZODIAC HERO</div>
							<img class="mainImg" src={logo} />

							<Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}><button class="explore">Explore Our NFT</button></Link>
							<div class="downloadDiv"><img class="download" src={download} /></div>

						</div>

					</div>
					<Ticker />

					<Element name="story">
						<Story />
					</Element>

					<Ticker />

					<Element name="types">
						<NftTypes />
					</Element>

					<Element name="gallery">
						<Carousel />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="conT2">MINTING</div>
							<div class="note">YOU CAN CHOOSE TYPE OF NFT AND YOUR ZODIAC BY CONNECTING YOUR WALLET TO POLYGON NETWORK</div>
							<div class="mintDiv">
								<div id="content" class="selectedImg2"> </div>
								<div class="mintCon">
									<div class="totalSupply">{this.state.totalSupplyChanging} / {this.state._totalSupplyVar}</div>
									{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

										(<div class="price"><div>Limit Reached!</div></div>) :
										(<div class="price"><div onClick={osL}>TRADE ON OPENSEA</div></div>)}
									<p class="price" onClick={linkCon}>0x68...55AA</p>

									<div class="priceBox">
										<div class="prices">USDC 10 for Normal NFT</div>
										<div class="prices">USDC 30 for Rare NFT</div>
										<div class="pricesN">( USDC on polygon network <img class="poly" src={poly} /> )</div>

										<p></p>
										<div class="prices"><span class="quotes">- Immutable project metadata and images -</span></div>

										<div class="prices"><span class="quotes">- Unchangeable project data secures buyer's confidence -</span></div>
									</div>

									<div class="minting_count_button">
										<div class="center">
											<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
										</div>

										<div>
											<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
										</div>

										<div class="center">
											<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
										</div>
									</div>

									{this.state.walletAddress === '' ?

										(<div class="mintbuttondiv">
											<button onClick={this.walletConnect} class="btnfos-0-3">Connect</button>
										</div>) :
										(<div class="mintbuttondiv">
											{this.state._mintStatus === true ? (
												<div>

													<form onSubmit={this.onSubmit2}>
														<button class="btnfos-0-3" type="submit">
															Mint Here</button>
													</form>

												</div>
											) : (
												<div>
													{this.state._select < 1 ?
														(<div>
															<div onClick={this.selected} id="dogSelect1">{this.state.pressBtnName}<img class="down" src={down} /></div>
														</div>) :

														(<div>
															<div>
																<div onClick={this.selectedClose} id="dogSelect1">{this.state.pressBtnName}<img class="down" src={down} /></div>
															</div>
															<div id="dogSelect">
																<div class="selectImg"><div class="textH" onClick={this.img2F} >Normal Dog </div><div> <img src={img2} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img1F}>Rare Dog </div><div> <img src={img1} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img4F} >Normal Dragon </div><div><img src={img4} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img3F} >Rare Dragon </div><div> <img src={img3} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img6F}>Normal Goat</div><div><img src={img6} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img5F}>Rare Goat</div><div><img src={img5} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img8F} >Normal Horse</div><div><img src={img7} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img7F} >Rare Horse </div><div><img src={img8} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img10F} >Normal Monkey</div><div> <img src={img10} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img9F} >Rare Monkey</div><div><img src={img9} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img12F} >Normal Ox</div><div> <img src={img12} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img11F} >Rare Ox</div><div> <img src={img11} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img14F} >Normal Pig</div><div><img src={img14} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img13F}>Rare Pig</div><div> <img src={img13} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img16F} >Normal Rabbit</div><div><img src={img16} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img15F} >Rare Rabbit</div><div><img src={img15} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img18F} >Normal Rat</div><div><img src={img18} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img17F} >Rare Rat</div><div><img src={img17} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img20F} >Normal Rooster</div><div><img src={img20} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img19F} >Rare Rooster</div><div> <img src={img19} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img22F} >Normal Snake</div><div><img src={img22} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img21F} >Rare Snake</div><div><img src={img21} /></div></div>

																<div class="selectImg"><div class="textH" onClick={this.img24F}>Normal Tiger</div><div> <img src={img24} /></div></div>
																<div class="selectImg"><div class="textH" onClick={this.img23F} >Rare Tiger</div><div> <img src={img23} /></div></div>
															</div>
														</div>)}

													{this.state._approved !== true ?
														(<form onSubmit={this.approve}>
															<button class="btnfos-0-3" type="submit">
																Approve</button>
														</form>) :

														(<form onSubmit={this.onSubmit2}>
															<button class="btnfos-0-3" type="submit">Mint Here</button>
														</form>)}
												</div>

											)}
										</div>)}
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your NFT</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div class="success-message">
												<h2>Successful NFT Minting!</h2>
												<p>Your NFT has been successfully minted.</p>
											</div>)
											: null}

									</div>

								</div>

							</div>

						</div>

					</Element>

					<Element name="team">
						<Team />
					</Element>

					<Ticker />

					<Footer />

				</div>

			</div>)
	}
}

export default App;
