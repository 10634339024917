import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/LOGO.jpeg';
import Ticker from './ticker';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import email from '../assets/email.png';
import teleg from '../assets/telegram.png';
import discord from '../assets/discord.png';
import csI from '../assets/cs.png';

const discrd = () => {
    window.open("https://discord.gg/VTTpckN25r");
}

const tweet = () => {
    window.open("https://twitter.com/zodiacheronft");
}

const tg = () => {
    window.open("https://t.me/ZodiacHeroGroup");
}

const mail = () => {
    window.open("mailto:Info@zodiachero.com");
}

const cs = () => {
    window.open("https://www.coinscope.co/coin/zh");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

            <div class="footer">

                <img class="logoF" src={logo} />
                <div class="icons">
                <img onClick={tweet} src={twitter} />
                <img onClick={discrd} src={discord} />
                <img onClick={tg} src={teleg} />
                <img onClick={mail} src={email} />
                <img onClick={cs} src={csI} />

                </div>

                <div class="copyright">Copyright © 2023 ZODIAC HERO. All Rights Reserved</div>
            </div>

        </div>
        )
    }
}

export default Footer;

