import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/8.gif';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

let currentIndex = 0;
const listMainSections = document.getElementsByClassName("listMain");

function slideSections() {
    currentIndex = (currentIndex + 1) % listMainSections.length;
    const currentSection = listMainSections[currentIndex];

    // Slide the current section from left to right
    currentSection.style.left = "-300px";

    // Insert the next section after the current section
    const nextIndex = (currentIndex + 1) % listMainSections.length;
    const nextSection = listMainSections[nextIndex];
    nextSection.style.left = "300px";

    // Animate the sliding effect
    animate(currentSection, { left: "300px" }, 500, function () {
        currentSection.style.left = "0";
        nextSection.style.left = "0";
    });
}

// Simple animation function
function animate(element, properties, duration, callback) {
    const start = performance.now();

    function step(timestamp) {
        const time = timestamp - start;

        Object.keys(properties).forEach(function (property) {
            const initialValue = parseFloat(getComputedStyle(element)[property]);
            const targetValue = parseFloat(properties[property]);
            const delta = (targetValue - initialValue) * (time / duration);
            element.style[property] = initialValue + delta + "px";
        });

        if (time < duration) {
            requestAnimationFrame(step);
        } else {
            callback();
        }
    }

    requestAnimationFrame(step);
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">
                    <div class="storyCon">
                        <div class="conT">ABOUT</div>
                        <p>Zodiac Hero is a captivating cartoon NFT collection that delves into the enchanting world of the Chinese zodiacs and their elemental powers. With a playful twist, this collection brings together the 12 zodiac animals, each representing a different element.</p>
                        <p>Join these whimsical characters as they embark on epic adventures, harnessing their elemental abilities to protect their realm. The courageous Fire Dragon soars through the skies, breathing fire to illuminate the darkest corners. The steadfast Earth Ox provides stability and fortitude, grounding the team in times of chaos.</p>
                        <p>Dive into the depths of the mystical ocean with the Water Rat, navigating through treacherous waters using its intuitive nature. The cunning Metal Monkey wields tools and gadgets, employing clever strategies to outwit their adversaries. Embrace the harmony of nature as the Wood Rabbit nurtures life and brings flourishing landscapes to existence.</p>
                        <p>Each NFT within the Zodiac Hero collection embodies the unique traits and symbolism of its respective Chinese zodiac animal and element. Collectors will be captivated by the vibrant artwork, intricate details.</p>
                        <p>Discover the thrill of owning these NFTs, not only as a delightful addition to your digital art collection but also as a gateway to the rich tapestry of Chinese mythology and astrology. Unleash the powers of the Chinese zodiacs and celebrate the balance between the elemental forces that shape our world in the extraordinary world of "Zodiac Hero."</p>
                     </div>

                    <img src={nft}></img>

                </div>

            </div>
        )
    }
}

export default Story;

